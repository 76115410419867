const segmentFormSelector = "form.segment";
const audienceTypeGroupSelector = 'input[name="segment[audience_type]"]';
const audienceTypeEditSelector = "#audience-type";
const audienceTypeCheckedSelector =
  'input[name="segment[audience_type]"]:checked';
const firebaseElementsSelector = ".firebase-fields";
const importedElementsSelector = ".imported-fields";

document.addEventListener("turbolinks:load", function () {
  const form = document.querySelector(segmentFormSelector);
  if (!form) {
    return;
  }

  // Simplified version without optional chaining or nullish coalescing
  const getAudienceType = () => {
    const checkedElement = document.querySelector(audienceTypeCheckedSelector);
    const editElement = document.querySelector(audienceTypeEditSelector);

    if (checkedElement && checkedElement.value) {
      return checkedElement.value;
    }

    if (editElement && editElement.value) {
      return editElement.value;
    }

    return null;
  };

  const audienceType = getAudienceType();

  //listeners for toggled elements
  document.querySelectorAll(audienceTypeGroupSelector).forEach((el) => {
    el.addEventListener("change", function (event) {
      toggleFormElements(event.target.value);
    });
  });

  //Assume all types are hidden to begin, then show only our type
  toggleFormElements(audienceType);
});

function toggleFormElements(selectedAudienceType) {
  const fireElements = document.querySelectorAll(firebaseElementsSelector);
  const importedElements = document.querySelectorAll(importedElementsSelector);

  //hide show elements based on form state/radio selection
  fireElements.forEach(function (el) {
    el.hidden = selectedAudienceType !== "firebase";
  });
  importedElements.forEach(function (el) {
    el.hidden = selectedAudienceType !== "imported";
  });
}
